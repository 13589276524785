/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';

import UrlRewrites from 'Route/UrlRewrites';
import { Router as SourceRouter } from 'SourceComponent/Router/Router.component';
import { isSignedIn } from 'Util/Auth';

import {
    ACCOUNT_FORGOT_PASSWORD,
    BLOG_POST_DETAILS,
    BLOG_POST_LISTING,
    CART,
    CHANGE_PASSWORD,
    CHECKOUT,
    CMS_PAGE,
    COMPARE,
    CONFIRM_ACCOUNT,
    CREATE_ACCOUNT,
    FORGOT_PASSWORD,
    HOME,
    LOGIN,
    LOGIN_AS_CUSTOMER,
    MENU,
    MY_ACCOUNT,
    OFFER,
    RMA_PAGE,
    SEARCH,
    SHARED_WISHLIST,
    STYLE_GUIDE,
    SWITCH_ITEMS_TYPE,
    URL_REWRITES
} from './Router.config';

export const CartPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/CartPage'));
export const Checkout = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/Checkout'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
export const CookiePopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/CookiePopup'));
export const DemoNotice = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/DemoNotice'));
export const Header = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/Header'));
export const HomePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/HomePage'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));
export const LoginAsCustomer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/LoginAsCustomer'));
export const OfferPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "offer" */ 'Route/OfferPage'));
export const PasswordChangePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/PasswordChangePage'));
export const SearchPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "search" */ 'Route/SearchPage'));
export const ConfirmAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/ConfirmAccountPage'));
export const MenuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/MenuPage'));
export const Footer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "footer" */ 'Component/Footer'));
export const NavigationTabs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/NavigationTabs'));
export const NewVersionPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NewVersionPopup'));
export const NotificationList = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NotificationList'));
export const WishlistShared = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/WishlistSharedPage'));
export const OfflineNotice = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/OfflineNotice'));
export const ProductComparePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/ProductComparePage'));
export const CreateAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/CreateAccount'));
export const LoginAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/LoginAccount'));
export const ForgotPasswordPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/ForgotPassword'));
export const SomethingWentWrong = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "something-went-wrong" */ 'Route/SomethingWentWrong'));
export const StyleGuidePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/StyleGuidePage'));
export const Breadcrumbs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/Breadcrumbs'));
export const BlogPostListing = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "post-list" */ 'Route/BlogPostListing'));
export const BlogPostDetails = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "post-details" */ 'Route/BlogPostDetails'));
export const RMA = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "rma" */ 'Route/RMA'));

/** @namespace Pwa/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

/** @namespace Pwa/Component/Router/Component/CheckAuth */
// eslint-disable-next-line react/prop-types
export const CheckAuth = ({ render: Component, ...rest }) => (
    <Route
      { ...rest }
      render={ (props) => (
          isSignedIn() === true
              ? <Component { ...props } />
              : <Redirect to="/customer/account/login/" />
      ) }
    />
);

/** @namespace Pwa/Component/Router/Component */
export class Router extends SourceRouter {
    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10,
            name: HOME
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25,
            name: SEARCH
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40,
            name: CMS_PAGE
        },
        {
            component: <CheckAuth path={ withStoreRegex('/cart') } exact render={ CartPage } />,
            position: 50,
            name: CART
        },
        {
            component: <CheckAuth path={ withStoreRegex('/checkout/:step?') } render={ Checkout } />,
            position: 55,
            name: CHECKOUT
        },

        {
            component: <Route path={ withStoreRegex('/:account*/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60,
            name: CHANGE_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/:account*/create/') } render={ (props) => <CreateAccountPage { ...props } /> } />,
            position: 61,
            name: CREATE_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/loginascustomer/login/index') } render={ (props) => <LoginAsCustomer { ...props } /> } />,
            position: 62,
            name: LOGIN_AS_CUSTOMER
        },
        {
            component: <Route path={ withStoreRegex('/:account*/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 86,
            name: LOGIN
        },
        {
            component: <Route path={ withStoreRegex('/:account*/forgotpassword/') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
            position: 63,
            name: ACCOUNT_FORGOT_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/:account*/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65,
            name: CONFIRM_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/my-account/:tab?/page/:pageId?/saldo/:saldo') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 66,
            name: MY_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/my-account/:tab?/saldo/:saldo') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 65,
            name: MY_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/my-account/:tab?/page/:pageId?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 68,
            name: MY_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/my-account/:tab?/:order?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 70,
            name: MY_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/forgot-password') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 71,
            name: FORGOT_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/offers/:id/') } render={ (props) => <OfferPage { ...props } /> } />,
            position: 75,
            name: OFFER
        },
        {
            component: <Route path={ withStoreRegex('/blog/page/:pageId') } render={ (props) => <BlogPostListing { ...props } /> } />,
            position: 76,
            name: BLOG_POST_DETAILS
        },
        {
            component: <Route path={ withStoreRegex('/blog/:categoryId/page/:pageId') } render={ (props) => <BlogPostListing { ...props } /> } />,
            position: 77,
            name: BLOG_POST_DETAILS
        },
        {
            component: <Route path={ withStoreRegex('/blog/:categoryId/:postId') } render={ (props) => <BlogPostDetails { ...props } /> } />,
            position: 78,
            name: BLOG_POST_DETAILS
        },
        {
            component: <Route path={ withStoreRegex('/blog/:categoryId') } render={ (props) => <BlogPostListing { ...props } /> } />,
            position: 79,
            name: BLOG_POST_DETAILS
        },
        {
            component: <Route path={ withStoreRegex('/blog') } render={ (props) => <BlogPostListing { ...props } /> } />,
            position: 80,
            name: BLOG_POST_LISTING
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 90,
            name: MENU
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ (props) => <WishlistShared { ...props } /> } />,
            position: 100,
            name: SHARED_WISHLIST
        },
        {
            component: <Route path={ withStoreRegex('/compare') } render={ (props) => <ProductComparePage { ...props } /> } />,
            position: 110,
            name: COMPARE
        },
        {
            component: <Route path={ withStoreRegex('/styleguide') } render={ (props) => <StyleGuidePage { ...props } /> } />,
            position: 200,
            name: STYLE_GUIDE
        },
        {
            component: <Route path={ withStoreRegex('/rma') } render={ (props) => <RMA { ...props } /> } />,
            position: 85,
            name: RMA_PAGE
        },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000,
            name: URL_REWRITES
        }
    ];
}

export default Router;
